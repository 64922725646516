import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { routeCreateOrganizationMembers } from "../routes";
import { useSetOrganizationValues } from "./create-organization-layout";
import { OrganizationType, UserAppRole } from "../../API";
import {
  clearCreateOrganizationSessionStorage,
  createOrganizationText,
  maxWidth,
} from "../../utils";
import { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import ColorThief from "colorthief";
import { Option } from "../../components/select";
import FormSelect from "../../components/form-select";

export type OrganizationValues = {
  id: string;
  name: string;
  accountingName?: string;
  accountingColor?: string;
  type: OrganizationType;
  submitted: boolean;
};

const optionsOrganizationType: Option[] = Object.values(OrganizationType).map(
  (organizationType) => ({
    value: organizationType,
    label:
      organizationType === OrganizationType.company
        ? "Unternehmen"
        : "Treuhand",
  })
);

const Organization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const { user, organizationValues, setOrganizationValues } =
    useSetOrganizationValues();

  const [fetchingCompanyProfile, setFetchingCompanyProfile] = useState(false);

  const initialValues: OrganizationValues = organizationValues;

  useEffect(() => {
    if (
      user.role !== UserAppRole.accountant &&
      user.isSSO &&
      !organizationValues.accountingName &&
      !fetchingCompanyProfile
    ) {
      setFetchingCompanyProfile(true);
      const fetchCompanyProfile = async () => {
        try {
          const response = await API.post("expenslyREST", "/auth/bexio/proxy", {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            },
            body: {
              organizationId: organizationValues.id,
              email: user.email,
              path: "/2.0/company_profile/1",
              method: "GET",
            },
          });

          if (!response) {
            throw new Error("No company profile found");
          }

          // Get the dominant hex color of the company logo
          // if the logo is available
          // logo is a base64 encoded image
          const logo = response.logo_base64;
          if (logo) {
            const colorThief = new ColorThief();
            const img = new Image();
            img.src = `data:image/png;base64,${logo}`;
            img.onload = () => {
              const color = colorThief.getColor(img);
              if (color) {
                setOrganizationValues({
                  ...organizationValues,
                  accountingName: response.name,
                  accountingColor: `#${color[0].toString(
                    16
                  )}${color[1].toString(16)}${color[2].toString(16)}`,
                });
              }
            };
          } else {
            setOrganizationValues({
              ...organizationValues,
              accountingName: response.name,
            });
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchCompanyProfile();
    }
  }, [
    fetchingCompanyProfile,
    organizationValues,
    setOrganizationValues,
    user.email,
    user.isSSO,
    user.role,
  ]);

  useEffect(() => {
    if (
      fetchingCompanyProfile &&
      organizationValues.accountingName &&
      !organizationValues.submitted
    ) {
      setOrganizationValues({
        ...organizationValues,
        name: organizationValues.accountingName,
      });
      setFetchingCompanyProfile(false);
    }
  }, [fetchingCompanyProfile, organizationValues, setOrganizationValues]);

  return fetchingCompanyProfile ? (
    <Spinner />
  ) : (
    <VStack maxWidth={maxWidth} width="full" alignItems="start" spacing="12px">
      <Text fontSize={36} fontWeight="medium" color="gray.900">
        {`Neue ${createOrganizationText}`}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, helpers) => {
          setOrganizationValues({
            ...organizationValues,
            id: values.id,
            name: values.name,
            type: values.type,
            submitted: true,
          });
          navigate(routeCreateOrganizationMembers);
        }}
      >
        {(props) => (
          <Form style={{ width: "100%" }}>
            <VStack spacing="20px">
              <Field
                name="name"
                validate={(value: string) => {
                  let error;
                  if (!value) {
                    error = "Name ist erforderlich";
                  }
                  return error;
                }}
              >
                {({ field, form }: FieldProps<number, OrganizationValues>) => (
                  <FormControl
                    isInvalid={
                      (form.errors.name && form.touched.name) as boolean
                    }
                  >
                    <FormLabel fontSize={14} color="gray.700">
                      Name
                    </FormLabel>
                    <Input {...field} placeholder="Name der Organisation" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="type">
                {({ field }: FieldProps<number, OrganizationValues>) => (
                  <FormControl>
                    <FormLabel fontSize={14} color="gray.700">
                      Art der Organisation
                    </FormLabel>
                    <FormSelect
                      field={field}
                      props={props}
                      options={optionsOrganizationType}
                      isDisabled={user.role === UserAppRole.accountant}
                    />
                  </FormControl>
                )}
              </Field>
              <HStack width="full">
                <Button
                  width="full"
                  colorScheme="error"
                  onClick={() => {
                    clearCreateOrganizationSessionStorage();
                    navigate(from, { replace: true });
                  }}
                >
                  Abbrechen
                </Button>
                <Button width="full" colorScheme="primary" type="submit">
                  Weiter
                </Button>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};

export default Organization;
