import { Box, HStack, Input, Textarea, VStack } from "@chakra-ui/react";
import { DataTable } from "./data-table";
import { createColumnHelper } from "@tanstack/react-table";
import {
  AccountingSystemBookingAccount,
  BookingAccountValue,
} from "../routes/create-organization/booking-accounts";
import { useMemo, useState } from "react";

import IconAlertCircle from "../assets/icons/alert-circle.svg";
import TooltipIcon from "./tooltip-icon";
import RowDragHandleCell from "./row-drag-handle-cell";
import { TransactionCategory } from "../models";
import { TaxType } from "../API";
import AddButton from "../routes/create-organization/add-button";
import { v4 as guid } from "uuid";
import RemoveButton from "../routes/create-organization/remove-button";
import Select, { Option } from "./select";
import { getTransactionCategoryIcon, isValidBookingAccount } from "../utils";

const columnHelper = createColumnHelper<BookingAccountValue>();

const taxTypes: TaxType[] = Object.values(TaxType);

const getTaxTypeName = (taxType: TaxType) => {
  switch (taxType) {
    case TaxType.investments:
      return "Investition";
    case TaxType.materials:
      return "Material";
    case TaxType.earnings:
      return "Umsatz";
  }
};

type BookingAccountsTableProps = {
  typeNamePlural: string;
  transactionCategories?: TransactionCategory[];
  bookingAccounts?: BookingAccountValue[];
  hasAccountingSystemBookingAccounts: boolean;
  accountingSystemBookingAccounts?: AccountingSystemBookingAccount[];
  remove: <BookingAccountValue>(
    index: number
  ) => BookingAccountValue | undefined;
  replace: <BookingAccountValue>(
    index: number,
    value: BookingAccountValue
  ) => void;
  push: <X = any>(obj: X) => void;
  move: (from: number, to: number) => void;
};

const BookingAccountsTable = ({
  typeNamePlural,
  transactionCategories,
  bookingAccounts,
  hasAccountingSystemBookingAccounts,
  accountingSystemBookingAccounts,
  remove,
  replace,
  push,
  move,
}: BookingAccountsTableProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const optionsAccountingSystemBookingAccounts: Option[] | undefined = useMemo(
    () =>
      accountingSystemBookingAccounts?.map((aba) => ({
        label: `${aba.account_no} - ${aba.name}`,
        value: aba.account_no,
      })),
    [accountingSystemBookingAccounts]
  );

  const optionsTransactionCategories: Option[] | undefined = useMemo(
    () =>
      transactionCategories?.map((tc) => ({
        label: tc.name,
        value: tc.id,
        icon: getTransactionCategoryIcon(tc.icon),
      })),
    [transactionCategories]
  );

  const optionsTaxTypes: Option[] = useMemo(
    () =>
      taxTypes.map((tt) => ({
        label: getTaxTypeName(tt),
        value: tt,
      })),
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "drag-handle",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      }),
      columnHelper.accessor("accountNumber", {
        cell: (info) => {
          const isValid = isValidBookingAccount(
            hasAccountingSystemBookingAccounts,
            accountingSystemBookingAccounts,
            info.getValue()
          );
          const value = optionsAccountingSystemBookingAccounts?.find(
            (o) => o.value === info.getValue().toString()
          );
          return optionsAccountingSystemBookingAccounts ? (
            <HStack>
              <Select
                value={value}
                options={optionsAccountingSystemBookingAccounts}
                onChange={(value) => {
                  replace(info.row.index, {
                    ...info.row.original,
                    accountNumber: value?.value,
                  });
                }}
                isValid={isValid}
                placeholder={info.getValue()}
                width="240px"
              />
              {!isValid && (
                <TooltipIcon
                  header={`Das Konto ${info.getValue()} ist in bexio nicht vorhanden`}
                  body={`Das gewählte Konto ${info.getValue()} ist in bexio nicht vorhanden. Bitte
        ändere das Konto oder lösche die Spesenkategorie.`}
                  icon={IconAlertCircle}
                />
              )}
            </HStack>
          ) : (
            <Input
              defaultValue={info.getValue()}
              onBlur={(event) => {
                replace(info.row.index, {
                  ...info.row.original,
                  accountNumber: event.target.value,
                });
              }}
            />
          );
        },
        header: "Kontonummer",
      }),
      columnHelper.accessor("name", {
        cell: (info) => (
          <Textarea
            defaultValue={info.getValue()}
            onBlur={(event) => {
              replace(info.row.index, {
                ...info.row.original,
                name: event.target.value.replaceAll(/\r\n/g, "\n"),
              });
            }}
            // focus automatically if new item
            autoFocus={info.getValue().length === 0}
            resize="vertical"
            minH="62px"
          />
        ),
        header: "Kontoname",
      }),
      columnHelper.accessor("transactionCategoryId", {
        cell: (info) => {
          const value = optionsTransactionCategories?.find(
            (o) => o.value === info.getValue()
          );
          return (
            <Select
              value={value}
              options={optionsTransactionCategories}
              onChange={(value) => {
                replace(info.row.index, {
                  ...info.row.original,
                  transactionCategoryId: value?.value,
                });
              }}
              width="160px"
            />
          );
        },
        header: "Buchungskategorie",
      }),
      columnHelper.accessor("taxType", {
        cell: (info) => {
          const value = optionsTaxTypes.find(
            (o) => o.value === info.getValue()
          );
          return (
            <Select
              value={value}
              options={optionsTaxTypes}
              onChange={(value) => {
                replace(info.row.index, {
                  ...info.row.original,
                  taxType: value?.value,
                });
              }}
              width="120px"
            />
          );
        },
        header: "Kontoart",
      }),
    ],
    [
      accountingSystemBookingAccounts,
      hasAccountingSystemBookingAccounts,
      optionsAccountingSystemBookingAccounts,
      optionsTaxTypes,
      optionsTransactionCategories,
      replace,
    ]
  );

  return (
    <VStack alignItems="start" width="full" spacing="16px">
      <Box
        width="full"
        minHeight={{ base: "auto", md: "418px" }}
        maxHeight="51vh"
        overflowY="auto"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
      >
        <DataTable
          typeNamePlural={typeNamePlural}
          columns={columns}
          data={bookingAccounts}
          move={move}
          state={
            !transactionCategories ||
            !bookingAccounts ||
            (hasAccountingSystemBookingAccounts &&
              !accountingSystemBookingAccounts)
              ? "loading"
              : "success"
          }
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </Box>
      <HStack>
        <AddButton<BookingAccountValue>
          push={push}
          data={{
            id: guid(),
            name: "",
            accountNumber: "0",
            taxType: TaxType.investments,
            transactionCategoryId:
              transactionCategories && transactionCategories.length > 0
                ? transactionCategories[0].id
                : "",
          }}
          isDisabled={!!bookingAccounts?.find((ba) => ba.name.length === 0)}
        />
        <RemoveButton
          remove={remove}
          index={selectedIndex}
          onComplete={() =>
            setSelectedIndex(
              selectedIndex && selectedIndex > 0 ? selectedIndex - 1 : 0
            )
          }
          isDisabled={selectedIndex === undefined}
        />
      </HStack>
    </VStack>
  );
};

export default BookingAccountsTable;
